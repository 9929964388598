<template>
  <div class="d-flex">
    <v-card class="col-12 ml-6 mt-6">
      <v-card-title>
        <span class="blue--text text-h6"> Saha Bilgileri </span>
      </v-card-title>
      <v-divider />
      <div class="col-12 d-flex">
        <div class="col-md-6">
          <v-col cols="12">
            <div class="d-flex">
              <div class="col-4 text-left font-weight-black">
                Saha:
              </div>
              <div class="col-6 text-left">
                {{ farmdata.farmName }}
              </div>
            </div>

            <v-divider />
            <div class="d-flex">
              <div class="col-4 text-left font-weight-black">
                Yerel Saat:
              </div>
              <div class="col-6 text-left">
                {{ farmdata.timezone.name }}
              </div>
            </div>

            <v-divider />
            <div class="d-flex">
              <div class="col-4 text-left font-weight-black">
                Kurulu Güç:
              </div>
              <div class="col-6 text-left">
                {{ farmdata.NominalPlantPower.value }}{{ farmdata.NominalPlantPower.unit }}
              </div>
            </div>

            <v-divider />
            <div class="d-flex">
              <div class="col-4 text-left font-weight-black">
                Elektrik fiyatı:
              </div>
              <div class="col-6 text-left">
                {{ farmdata.income.electricityPrice }}
                {{ farmdata.income.currency }}
              </div>
            </div>

            <v-divider />
            <div class="d-flex">
              <div class="col-4 text-left font-weight-black">
                CO2 Emisyon Azaltma Katsayısı:
              </div>
              <div class="col-6 text-left d-flex">
                {{ farmdata.CO2EmissionReductionCoefficient.value
                }}{{ farmdata.CO2EmissionReductionCoefficient.unit }}
              </div>
            </div>

            <v-divider />
            <div class="d-flex">
              <div class="col-4 text-left font-weight-black">
                Ülke:
              </div>
              <div class="col-6 text-left">
                {{ farmdata.Country }}
              </div>
            </div>

            <v-divider />
            <div class="d-flex">
              <div class="col-4 text-left font-weight-black">
                Şehir:
              </div>
              <div class="col-6 text-left">
                {{ farmdata.City }}
              </div>
            </div>

            <v-divider />
            <div class="d-flex">
              <div class="col-4 text-left font-weight-black">
                Adres:
              </div>
              <div class="col-6 text-left">
                {{ farmdata.adress }}
              </div>
            </div>

            <v-divider />
            <div class="d-flex">
              <div class="col-4 text-left font-weight-black">
                Konum enlem:
              </div>
              <div class="col-6 text-left">
                {{ farmdata.location.lat }}
              </div>
            </div>

            <v-divider />
            <div class="d-flex">
              <div class="col-4 text-left font-weight-black">
                Konum boylam:
              </div>
              <div class="col-6 text-left">
                {{ farmdata.location.lng }}
              </div>
            </div>

            <v-divider />
          </v-col>
        </div>

        <div class="col-md-6">
          <div id="map" />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { init } from '@/utils/gmaps';
import moment from 'moment';
import helper from '@/api/helper';
import { getFarm } from '@/api/Request/farm';

export default {
  data: () => ({
    farmdata: {},
    timezone: '',
    timezoneslist: [],
    interval: null,
    google: null,
    map: null,
    companyCode: null,
    prefix: null,
  }),
  watch: {},
  created() {
    this.newFarmdata();
  },
  async mounted() {
    this.google = await init();
    const options = {
      zoom: 2,
      center: { lat: 35, lng: 40 },
      gestureHandling: 'greedy',
    };

    const { google } = this;
    this.map = new google.maps.Map(document.getElementById('map'), options);

    this.selectedFarmFunc();
  },
  methods: {
    async initMap() {
      const self = this;

      this.google = await init();
      const options = {
        zoom: 15,
        center: { lat: 39.749006, lng: 29.960302 },
        gestureHandling: 'greedy',
      };
      const myLatlng = { lat: 39.749006, lng: 29.960302 };
      const { google } = this;
      if (self.farmdata.location.lat != null) {
        myLatlng.lat = parseFloat(self.farmdata.location.lat);
        options.center.lat = parseFloat(self.farmdata.location.lat);
      }
      if (self.farmdata.location.lat != null) {
        myLatlng.lng = parseFloat(self.farmdata.location.lng);
        options.center.lng = parseFloat(self.farmdata.location.lng);
      }

      this.map = new google.maps.Map(document.getElementById('map'), options);
      // Create the initial InfoWindow.
      const infoWindow = new google.maps.InfoWindow({
        content: self.farmdata.farmName,
        position: myLatlng,
      });

      infoWindow.open(this.map);
    },

    selectedFarmFunc() {
      const storage = window.localStorage;
      const userCompany = JSON.parse(window.atob(storage.getItem('user_company')));
      this.companyCode = userCompany.companyCode;
      this.prefix = userCompany.prefix;

      this.getFarm();
    },
    // seçilen saha bilgisini alma
    getFarm() {
      const params = {
        condiniton: {
          companyCode: this.companyCode,
          prefix: this.prefix,
        },
      };

      getFarm(params).then((res) => {
        if (!helper.is_Empty(res.data.data)) {
          this.farmdata = { ...this.farmdata, ...res.data.data[0] };
          this.initMap();
        }
      });
    },

    newFarmdata() {
      this.farmdata = {
        farmName: '',

        location: {
          lat: null,
          lng: null,
        },
        status: true,
        companyCode: '',

        adress: '',
        Country: '',
        City: '',
        startdatatime: moment().format('YYYY-MM-DD'),
        modulCount: '',
        moduleArea: '',
        timezone: {
          country: 'TR',
          name: 'Europe/Istanbul',
          status: 'Canonical',
          offset: '+03:00',
          link: '',
        },
        CO2EmissionReductionCoefficient: {
          unit: '',
          value: '',
        },
        income: {
          electricityPrice: '',
          currency: '',
        },
        NominalPlantPower: {
          unit: '',
          value: '',
        },
      };
    },
  },
};
</script>

<style>
#userForm .col.col-12 {
  padding: 0;
}
#map {
  height: 650px;
  width: 100%;
  min-width: 480px;
}
</style>
